$primary-color: #2980b9;
$trafic-red: rgb(255,86,79);
$trafic-yellow: rgb(255,182,42);
$trafic-green: rgb(37,199,58);

body {
    font-family: sans-serif;
  }
  
.navbar {
background-color: $primary-color;
border: solid 5px $primary-color;
border-radius: 5px;
box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
color: white;
font-size: 200%;
text-align: center;
}

.btn {
border: solid 1px gray;
background-color: white;
border-radius: 5px;
font-size: medium;
margin: 5px;
box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
    

  &-primary {
    background-color: $primary-color;
    color: white;
    border: solid 1px $primary-color;
  }

  &-delete {
    color: $trafic-red;
    background-color: white;
    border: solid 1px $trafic-red;
    display: none;
  }

  &-disabled {
    box-shadow: none;
    color: white;
    background-color: #297fb985;
  }

}

.card {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  border-bottom: solid 2px white;
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-direction: column;
  height: auto;
  margin: none;
  max-height: 100px;
  max-width: 200px;
  min-width: 250px;
  padding-bottom: 5px;
  text-align: center;
  width: 33%;
  word-wrap: break-word;
}

.title {
  font-size: 250%;
  height: 60%;
  background-color: $primary-color;
  color: white;
  padding: 5px;
  margin-top: none;
  margin-bottom: 5%;
  transition: height 1s ease;
  transition-delay: 0.75s;
  display: flex;
  flex-direction: row;
  max-height: 20px;
  min-height: 20px;
  border: solid 2px $primary-color;
  border-radius: 5px 5px 0 0;
}

.title a {
  font-size: 50%;
  flex: 3;
}

.content {
  margin: 5;
  margin-bottom: 10px;
  padding: 5px;
  padding-bottom: 10px;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  overflow: auto;
  max-width: none;
  max-height: none;
  min-width: 200px;
  min-height: 200px;
}

.dialog .content {
  display: flex;
  flex-direction: column;
  border-bottom: solid 2px white;
  border-radius: 0 0 5px 5px;
}

.background-blur {
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  display: none;
}

.footer {
  background-color: white;
}

textarea {
  resize: none;
}

@keyframes bluriapolis {
  from {
    filter: blur(0);
  }

  to {
    filter: blur(5px);
  }
}

.space {
  width: 100%;
  height: 80%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 10px;
  padding-top: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.entry {
  border: solid 1px gray;
  margin: 5px;
  font-size: large;
  height: 80%;
  box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 20%);
  
  &:focus {
    border: solid 1px rgb(196 196 196);
  }
}


.traffic {
  height: 10px;
  width: 10px;
  font-size: x-small;
  border: none;
  color: transparent;
  background-color: #dadadab6;
  font-weight: bold;
  border-radius: 50%;
  margin: 5px 2.5px;
  padding: 1px;
  transition: width 0.5s ease, height 0.5s ease;
  transition-delay: 0.5s;
  
  &-red {
    background-color: $trafic-red;
  }

  &-yellow {
    background-color: $trafic-yellow;
  }

  &-green {
    background-color: $trafic-green;
  }


  &:hover {
    color: rgb(75 75 75 / 50%);
  }
}

@media only screen and (max-width: 1000px) {
  textarea {
    height: 50%;
  }

  .message .card {
    top: 15%;
    left: 15%;
    height: 65%;
    z-index: 200;
  }

  .message .card .title {
    height: 10%;
  }
}

@media only screen and (max-width: 600px) {

  .btn {
    font-size: large;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px;

    &-delete {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 90%;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 50;
    }
  }

  .background-blur {
    display: block;
    z-index: 2;
  }

  .entry {
    font-size: xx-large;
  }

  .footer > .btn {
    height: 50px;
    width: 50%;
    position: relative;
    align-self: center;
    flex: 2;
    margin: 5px;
  }

  .footer {
    position: fixed;
    height: 50px;
    width: 90%;
    max-width: 600px;
    bottom: 0;
    left: 0;
    padding: 10px;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
  }

  .space {
    display: inline;

    /* center the content */
    margin-left: auto;
    margin-right: auto;
  }

  .card {
    margin: 20px 0;
    min-height: 10px;
    max-height: 100px;
    max-width: none;
    width: 99%;
    transition: width 0.5s linear, top 0.5s linear;
    

    &-selected {
      position: fixed;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      z-index: 1000;
  
      .title {
        height: 20%;
      }

    }

    &-selected::backdrop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: red;
      border: red solid 1px;
      background-color: $primary-color;
      filter: blur(5px);
      z-index: 999;

    }
  }
    
    

  .dialog {
    position: fixed;
    border: solid 3px $primary-color;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow: auto;
    resize: none;

  .title {
      padding: 10px 5px 10px 5px;
      font-size: 200%;
      background-color: $primary-color;
      color: white;
      border: none;
      margin-top: none;
      margin-bottom: 5%;
      transition: height 1s ease;
      transition-delay: 0.75s;
      display: flex;
      flex-direction: row;
    }
}

  .traffic {
    display: none;
  }

  .title a {
    font-size: x-large;
    flex: 3;
  }
}

